<template>
    <div>
        <h2 class="text-center">Coverage Map</h2>
        <br>

        <div style="background-color: white; padding: 2%;">
            <div class="row mb-3">
                <div class="col-2 align-self-center" v-for="(prov, index) in settings.providers" :key="index">
                    <input class="mr-2" type="checkbox" v-model="provider" :value="prov.name" />
                    <strong>{{ prov.name }}: </strong>
                    <img :src="`http://maps.google.com/mapfiles/kml/paddle/${prov.color}.png`" alt="">
                </div>

                <div class="form-group mr-1 d-flex align-self-center">
                    <input style="max-width: 100px;margin-bottom: 0px !important;" type="number" min="1" v-model="distance" class="form-control" placeholder="Distance in miles"/>
                    <div class="ml-1 align-self-end">mi</div>
                </div>
            </div>
            <gmap-map
                :center="center"
                :zoom="radiusToZoom"
                style="width:100%;  height: 600px;"
            >
                <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWindowOpen"
                    @closeclick="closeProxy"
                >
                </gmap-info-window>
                <gmap-marker
                    :key="index"
                    v-for="(m, index) in data"
                    :position="m.position"
                    :icon="m.icon"
                    @click="toggleInfoWindow(m,index)"
                ></gmap-marker>
                <gmap-circle
                    v-if="selected"
                    :center="center"
                    :radius="(distance * 1609.344)"
                    :visible="true"
                    :draggable="true"
                    :options="{fillColor:'red',fillOpacity:0.3}"
                ></gmap-circle>
            </gmap-map>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';

export default {
name: "CoverageMap",
    data() {
        return {
            radiusToZoom: 4.4,
            provider: [],
            center:{ lat: 39.0119, lng: -98.4842},
            markers: [],
            infoWindowPos: null,
            selected: null,
            distance: 15,
            infoWindowOpen: false,
            currentMidx: null,
            infoOptions:{
                content: '',
                pixelOffset:{
                    width: 0,
                    height: -35
                }
            }
        };
    },
    components:{
    },
    mounted() {
        this.geolocate();
    },
    watch: {
        distance: function () {
            this.radiusToZoom = this.toZoom();
        }
    },
    computed: {
        ...mapState(['settings']),
        data() {
            if(!this.provider.length) return this.markers;
            else return this.markers.filter((marker) => { return this.provider.includes(marker.provider) })
        },
    },
    methods: {
        toZoom(){
            if(!this.selected || !this.distance) return 4.4
            return Math.round(14-Math.log(this.distance)/Math.LN2);
        },
        closeProxy() {
            this.infoWindowOpen=false;
            this.selected = null;
            this.$forceUpdate();
            this.$nextTick(() => {
                this.radiusToZoom = this.toZoom();
                this.center = { lat: 39.0119, lng: -98.4842};
            });
        },
        setProvider(provider) {
          if(!this.provider.length) this.provider = null;
          else this.provider = provider;
        },
        geolocate: function() {
            var request = {'apikey': this.$root.apikey};
            axios.get(`${this.$root.serverUrl}/admin/reports/coverage-map/map-markers`, {params:request}).then(function(resp){
                const data = resp.data.data;
                const providers = Object.fromEntries(this.settings.providers.map(provider => ([provider.name, provider.color])))
                this.markers = [];
                for(let i=0; i<data.length; i++){
                    if(typeof providers[data[i].provider] !== "undefined") {
                        let icon = (typeof providers[data[i].provider] !== "undefined")
                            ? `http://maps.google.com/mapfiles/kml/paddle/${providers[data[i].provider]}.png`
                            : 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png';
                        let url = `${window.location.origin}/proxies?country=${data[i].country}&state=${data[i].state}&provider=${data[i].provider}`;
                        this.markers.push({
                            provider: data[i].provider,
                            position : data[i].position,
                            text:`
                            <span>Location: ${data[i].state}, ${data[i].country}.</span><br>
                            <span>Provider: ${data[i].provider}.</span><br>
                            <span><a href="${url}&status=active" target="_blank">Active Proxies: ${data[i].active}.</a> </span><br>
                            <span><a href="${url}&status=pending" target="_blank">Pending Proxies: ${data[i].pending}.</a> </span><br>
                            <span><a href="${url}&status=bad" target="_blank">Bad Proxies: ${data[i].bad}.</a></span><br>
                        `,
                            icon
                        });
                    }
                }
                this.$root.preloader = false;
            }.bind(this));
           /*axios.get(`${this.$root.serverUrl}/admin/proxies/coordinates`, {params:request}).then(function(){
                this.$root.preloader = false;
            }.bind(this));*/
        },
        toggleInfoWindow: function(marker, idx){
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.text;

            this.center = marker.position;
            this.selected = marker;

            if(this.currentMidx === idx){
                this.infoWindowOpen = !this.infoWindowOpen;
            }else{
                this.infoWindowOpen = true;
                this.currentMidx = idx;
            }
            this.radiusToZoom = this.toZoom()
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>

</style>
